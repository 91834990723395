<template>
  <div>
    <!-- <b-form-group>
      <v-select
        v-model="menuItem"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="items"
        label="title"
        :reduce="title => title.title"
        input-id="menus"
        :clearable="false"
        @input="updateChildMenu"
      >

        <template #option="{ icon, title }">
          <feather-icon
            :icon="icon"
            size="16"
            class="align-middle mr-50"
          />
          <span> {{ title }}</span>
        </template>

        <template #selected-option="{ icon, title }">
          <feather-icon
            :icon="icon"
            size="16"
            class="align-middle mr-50"
          />
          <span> {{ title }}</span>
        </template>
      </v-select>
    </b-form-group> -->

    <li
      class="nav-item has-sub sidebar-group-active"
      :class="{
        'open': isOpen,
      }"
    >
      <b-link
        class="d-flex align-items-center"
        @click="() => isOpen = !isOpen"
      >
        <feather-icon :icon="currentIcon || 'CircleIcon'" />
        <span class="menu-title text-truncate">{{ menuItem }}</span>
      </b-link>
      <b-collapse
        v-model="isOpen"
        class="menu-content"
        tag="ul"
      >
        <li
          v-for="(child, key) in items"
          :key="key"
          :class="{
            'pale-bg': menuItem == child.title,
          }"
          class="nav-item text-primary"
          style="color: #104D9D !important; margin: 0 0 !important; padding: 10px 15px !important;"
          @click="updateChildMenuNew(child.title)"
        >
          <feather-icon :icon="child.icon || 'CircleIcon'" />
          {{ child.title }}
        </li>
      </b-collapse>
    </li>

    <ul>
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in selectedItems"
        :key="item.header || item.title"
        :item="item"
      />
    </ul>
  </div>
</template>

<script>
import { BFormGroup, BLink, BCollapse } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import VerticalNavMenuHeader from './vertical-nav-menu-header'
import VerticalNavMenuLink from './vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from './vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BFormGroup,
    BLink,
    BCollapse,
    vSelect,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      menuItem: this.items[0].title,
      currentIcon: this.items[0].icon,
      selectedItems: this.items[0].children,
    }
  },
  created() {
    const currentPath = this.$route.path.split('/')[1]
    const menu = this.items.find(o => o.title.toUpperCase() === currentPath.toUpperCase())
    if (menu) {
      this.menuItem = menu.title
      this.currentIcon = menu.icon
      this.selectedItems = menu.children
      this.isOpen = false
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    updateChildMenu() {
      const menu = this.items.find(o => o.title === this.menuItem)
      if (menu) {
        this.selectedItems = menu.children
      } else {
        this.selectedItems = ''
      }
    },
    updateChildMenuNew(title) {
      const menu = this.items.find(o => o.title === title)
      if (menu) {
        this.menuItem = title
        this.currentIcon = menu.icon
        this.selectedItems = menu.children
        this.isOpen = false
      } else {
        this.selectedItems = ''
      }
    },
  },
}
</script>
